import React from 'react';
import './Home.css'
import { Link } from "react-router-dom";
const Home = () => {
  return (
    <div className='home-wrapper'>
      <div className='homeimagesgrid firstlist'>
        <div className='gridimgmask'>
        <Link to="/EssayView/Gagra, Displacement By Inheritance">
          <div className='overlayeffect'>
          <img src='../../img/fr-fi.jpg' alt='' className='img-fluid'/>
          <div class="overlay">
            <div class="text">
              <div>
                <h6>Gagra, Displacement By Inheritance</h6>
              </div>
              <div>
                <p>Teona Chakvetadze</p>
              </div>
            </div>
          </div>
          </div>
          </Link>
          <p className='verticaltext'>43°17'09.9"N 40°15'52.5"E</p>
        </div>
      </div>
      <div className='homeimagesgrid secoundlist'>
        <div className='gridimgmask'>
        <Link to="/EssayView/A Village With A Single House">
        <div className='overlayeffect'>
          <img src='../../img/sr-fi.jpg' alt='' className='img-fluid'/>
          <div class="overlay">
            <div class="text">
              <div>
                <h6>A Village With A Single House</h6>
              </div>
              <div>
                <p>Nana Johkadze</p>
              </div>
            </div>
          </div>
        </div>
        </Link>
          <p className='verticaltext'>42°17'23.5"N 43°57'02.0"E</p>
        </div>

        <div className='gridimgmask'>
        <Link to="/EssayView/Gali. Home, Across The River">
        <div className='overlayeffect'>
        <img src='../../img/sr-si.jpg' alt='' className='img-fluid'/>
        <div class="overlay">
            <div class="text">
              <div>
                <h6>Gali. Home, Across The River</h6>
              </div>
              <div>
                <p>Lela Jobava</p>
              </div>
            </div>
          </div>
        </div>
        </Link>
          <p className='verticaltext'>42°37'40.8"N 41°44'11.9"E</p>
        </div>
      </div>

      <div className='homeimagesgrid thirdlist'>
        <div className='gridimgmask'>
        <Link to="/EssayView/Achigvara And Sokhumi. The Pre-War City And The Way Out Of It">
        <div className='overlayeffect'>
          <img src='../../img/tr-fi.jpg' alt='' className='img-fluid'/>
          <div class="overlay">
            <div class="text">
              <div>
                <h6>Achigvara And Sokhumi. The Pre-War City And The Way Out Of It</h6>
              </div>
              <div>
                <p>Nana Kvaratskhelia</p>
              </div>
            </div>
          </div>
          </div>
          </Link>
          <p className='verticaltext'>42°40'55.9"N 41°38'14.4"E</p>
        </div>
        <div className='gridimgmask'>
        <Link to="/EssayView/Kheiti, Tskhinvali And The Tserovani Settlement For The Displaced">
        <div className='overlayeffect'>
          <img src='../../img/tr-si.jpg' alt='' className='img-fluid'/>
          <div class="overlay">
            <div class="text">
              <div>
                <h6>Kheiti, Tskhinvali And The Tserovani Settlement For The Displaced</h6>
              </div>
              <div>
                <p>Maia Gochashvili</p>
              </div>
            </div>
          </div>
          </div>
          </Link>
          <p className='verticaltext'>42°15'32.6"N 43°58'30.7"E</p>
        </div>
      </div>

      <div className='homeimagesgrid fourthlist'>
        <div className='gridimgmask'>
        <Link to="/EssayView/A Stone In The Sea, Pshapi">
        <div className='overlayeffect'>
          <img src='../../img/fur-fi.jpg' alt='' className='img-fluid'/>
          <div class="overlay">
            <div class="text">
              <div>
                <h6>A Stone In The Sea, Pshapi</h6>
              </div>
              <div>
                <p>Marina Shengelia</p>
              </div>
            </div>
          </div>
          </div>
          </Link>
          <p className='verticaltext'>42°52'53.7"N 41°05'53.8"E</p>
        </div>

        <div className='gridimgmask'>
        <Link to="/EssayView/Harvests In Kurta">
        <div className='overlayeffect'>
          <img src='../../img/fur-si.jpg' alt='' className='img-fluid'/>
          <div class="overlay">
            <div class="text">
              <div>
                <h6>Harvests In Kurta</h6>
              </div>
              <div>
                <p>Keti Khachapuridze</p>
              </div>
            </div>
          </div>
          </div>
          </Link>
          <p className='verticaltext'>41°24'12.2"N 2°10'26.5"E</p>
        </div>

        <div className='gridimgmask'>
        <Link to="/EssayView/Akhalgori, An Introduction To My Story">
        <div className='overlayeffect'>
          <img src='../../img/fir-si.jpg' alt='' className='img-fluid'/>
          <div class="overlay">
            <div class="text">
              <div>
                <h6>Akhalgori, An Introduction To My Story</h6>
              </div>
              <div>
                <p>Ana Akhlouri</p>
              </div>
            </div>
          </div>
          </div>
          </Link>
          <p className='verticaltext'>42°07'28.1"N 44°29'04.6"E</p>
        </div>
      </div>

      <div className='homeimagesgrid fifthlist'>
        <div className='gridimgmask'>
          <Link to="/EssayView/Between Sokhumi and Tbilisi">
            <div className='overlayeffect'>
              <img src='../../img/fir-fi.jpg' alt='' />
              <div class="overlay">
                <div class="text">
                  <div>
                    <h6>Between Sokhumi and Tbilisi</h6>
                  </div>
                  <div>
                    <p>Elene Gogokhia</p>
                  </div>
                </div>
              </div>
            </div>
          </Link>
          <p className='verticaltext'>43°09'14.3"N 40°19'57.9"E</p>
        </div>
      </div>

    </div>
  );
};

export default Home;