import React from 'react';
import './Installation.css'

const Installation = () => {
  return (
    <div className='cmspages-wrapper'>
      <h2 className='cmspagetitle'><span>ACTIVATION PIECE</span></h2>
      <div className='towcoloumlayout'>
        <div className='pageinfosection'>
          <div className='infosections'>
            <h4>Title of the section</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <p>Archive of Spatial Knowledge is a collection of spatial and social memories contributed by individuals who were forcefully displaced, or are denied representation in places of their current residence. On the archive, voices that are pushed out of the physical spaces are hosted on a digital platform and attached to the locations of their origin, creating a protected pool of knowledge overlaid on the physical landscape.</p>

            <span class="titletextblack">Title of the Section</span>
            <p>Archive of Spatial Knowledge is a collection of spatial and social memories contributed by individuals who were forcefully displaced, or are denied representation in places of their current residence. On the archive, voices that are pushed out of the physical spaces are hosted on a digital platform and attached to the locations of their origin, creating a protected pool of knowledge overlaid on the physical landscape.</p>

            <span class="titletextblack">Privacy Policy</span>
            <p>Archive of Spatial Knowledge is a collection of spatial and social memories contributed by individuals who were forcefully displaced, or are denied representation in places of their current residence. On the archive, voices that are pushed out of the physical spaces are hosted on a digital platform and attached to the locations of their origin, creating a protected pool of knowledge overlaid on the physical landscape.</p>
          </div>
        </div>
        <div className='fixmenuoptionsections'>
        <ul>
          <li>
            <h4>Current Volume</h4>
            <p>The first volume of the archive focuses on  Russian-occupied territories of Georgia, and the narratives erased from the spaces during the ongoing aggression, spanning three decades.</p>
          </li>
        </ul>
        <ul className='menuimages'>
          <li>
            <img src="../img/menuimg.jpg" alt='menu img' />
          </li>
        </ul>
      </div>
      </div>
    </div>
  );
};

export default Installation;